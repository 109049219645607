import React from "react";
import { IntlProvider } from 'gatsby-plugin-intl'
import ContextProvider from './src/components/context/contextProvider';
// import DarkMode from "./src/components/darkMode"


export const wrapPageElement = ({ element }) => {
    return (
      <>
        <ContextProvider>
        <IntlProvider>
        {/* <div className="absolute top-24 right-2 z-20 md:right-5">
        <DarkMode />
        </div> */}
        <div className="absolute top-20 right-2 z-20 md:right-5">
        </div>
          {element}
        </IntlProvider>
        </ContextProvider>
      </>
    );
  };

  